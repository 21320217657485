import PropTypes from 'prop-types';

/**
 * React PropType schema for Search Item data
 */
export const MJESearchItem = PropTypes.shape({
  workbook_id: PropTypes.string,
  journal_batch_id: PropTypes.string,
  journal_header_id: PropTypes.number,
  journal_line_id: PropTypes.number,
  accounting_date: PropTypes.string,
  create_user: PropTypes.string,
  entered_dr: PropTypes.number,
  journal_currency: PropTypes.string,
  journal_name: PropTypes.string,
  processing_status: PropTypes.string,
  type: PropTypes.string,
  upload_name: PropTypes.string,
  workbook_name: PropTypes.string,
  wimj_link: PropTypes.string,
});

export default MJESearchItem;
